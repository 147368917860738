const filters = document.querySelectorAll('[data-behaviour="filter-toggle"]');

if (filters.length > 0) {
    filters.forEach((filter) => {
        filter.addEventListener('click', (e) => {
            e.preventDefault;

            const allFilters = document.querySelector(
                '[data-behaviour="all-filters"]'
            );

            allFilters.classList.toggle('is-active');
        });
    });
}
