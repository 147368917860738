const burger = document.querySelector('[data-behaviour="burger"]');
const mobileNav = document.querySelector('[data-behaviour="mobile-nav"]');
const body = document.body;

if (burger) {
    const closedClass = 'closed';
    const openClass = 'open';
    const searchCloseClass = 'closed';
    const searchOpenClass = 'open';

    burger.addEventListener('click', (e) => {
        e.preventDefault();
        body.classList.toggle('locked');
        const svg = burger.querySelector('svg');
        const childMenuButton = mobileNav.querySelectorAll(
            '[data-behaviour="child-menu-button"]',
        );
        const searchForm = document.querySelector(
            '[data-behaviour="search-form"]',
        );

        const childMenus = document.querySelectorAll(
            '[data-behaviour="child-menus"]',
        );

        svg.classList.toggle('active');
        searchForm.classList.toggle(searchCloseClass);
        searchForm.classList.toggle(searchOpenClass);
        mobileNav.classList.toggle(openClass);
        mobileNav.classList.toggle(closedClass);

        if (childMenuButton) {
            const childClosedClass = 'closed';
            const childOpenClass = 'open';

            childMenuButton.forEach((button) => {
                button.addEventListener('click', () => {
                    let childMenu;
                    let clickedChildButton = button.dataset.ref;
                    childMenus.forEach((menu) => {
                        if (menu.dataset.ref == clickedChildButton) {
                            childMenu = menu;
                        }
                    });

                    button.classList.toggle(childClosedClass);
                    button.classList.toggle(childOpenClass);

                    childMenu.classList.toggle('open');
                    childMenu.classList.toggle('closed');

                    if (childMenu.classList.contains('open')) {
                        const openHeight = childMenu.clientHeight + 'px';
                        childMenu.style.setProperty(
                            '--childOpenHeight',
                            openHeight,
                        );
                        childMenu.style.removeProperty('--childCloseHeight');
                    } else {
                        childMenu.style.setProperty(
                            '--childCloseHeight',
                            '0px',
                        );
                        childMenu.style.removeProperty('--childOpenHeight');
                    }
                });
            });
        }
    });
}
