const accordions = document.querySelectorAll('[data-behaviour="accordion"]');

if (accordions.length) {
  accordions.forEach((accordion) => {
    accordion.addEventListener('click', (e) => {
      const attrToggle = (el, attr) => el.getAttribute(attr) === 'false' ? el.setAttribute(attr, "true") : el.setAttribute(
        attr, "false")

      const accordionToggle = e.target;

      const content = document.querySelector(e.target.hash);
      if (!content) {
        return false;
      }

      e.preventDefault();

      if (content.classList.contains('show')) {
        accordionToggle.setAttribute('aria-expanded', 'false');
        accordionToggle.classList.remove('open');
        content.classList.remove('show');
        return;
      }

      const accordions = document.querySelectorAll('.accordion__content.show');
      for (let i = 0; i < accordions.length; i++) {
        accordionToggle.setAttribute('aria-expanded', 'false');
        accordionToggle.classList.remove('open');
        accordions[i].classList.remove('show');
      }

      // Toggle our content
      attrToggle(accordionToggle, 'aria-expanded');
      content.classList.toggle('show');
      accordionToggle.classList.toggle('open');
    });
  });
}

