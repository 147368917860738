document.addEventListener('DOMContentLoaded', () => {
    const videoContainers = document.querySelectorAll(
        '[data-behaviour="video-container"]',
    );

    videoContainers.forEach((el) => {
        const videoElement = el.querySelector('video');
        const actionButton = el.querySelector('[data-ref="video-action"]');
        const svgElement = actionButton?.querySelector('svg');
        const textSpan = actionButton?.querySelector('span[data-play-text]');
        const playText = textSpan?.getAttribute('data-play-text') || 'Play';
        const pauseText = textSpan?.getAttribute('data-pause-text') || 'Pause';
        const titleElement = el.querySelector('.full-video__title');
        const contentBG = el.querySelector('.full-video__content');
        const posterImage = videoElement.getAttribute('poster');

        if (videoElement && actionButton && svgElement && textSpan) {
            // Ensure the video is paused by default and add fade effect
            videoElement.pause();
            videoElement.classList.add('stopfade');
            svgElement.style.opacity = '1';

            // Add event listener for video end to trigger fade effect
            videoElement.addEventListener('ended', () => {
                videoElement.pause();
                videoElement.classList.add('stopfade');
                svgElement.style.opacity = '1';
                el.classList.add('is-playing');
                textSpan.textContent = playText;
                if (titleElement) {
                    titleElement.style.opacity = '1';
                }
                videoElement.setAttribute('poster', posterImage);
            });

            actionButton.addEventListener('click', (e) => {
                e.preventDefault();
                if (videoElement.paused) {
                    videoElement
                        .play()
                        .then(() => {
                            textSpan.textContent = pauseText;
                            svgElement.style.opacity = '0';
                            el.classList.add('is-playing');
                            videoElement.classList.remove('stopfade');
                            videoElement.removeAttribute('poster');
                            if (titleElement) {
                                titleElement.style.opacity = '0';
                            }
                        })
                        .catch((error) => {
                            console.error('Error playing video:', error);
                        });
                } else {
                    videoElement.pause();
                    textSpan.textContent = playText;
                    svgElement.style.opacity = '1';
                    el.classList.remove('is-playing');
                    videoElement.classList.add('stopfade');
                    if (titleElement) {
                        titleElement.style.opacity = '1';
                    }
                    videoElement.setAttribute('poster', posterImage);
                }
            });

            // Add event listener for video pause to show the SVG element
            videoElement.addEventListener('pause', () => {
                svgElement.style.opacity = '1';
                el.classList.remove('is-playing');
                textSpan.textContent = playText;
                if (titleElement) {
                    titleElement.style.opacity = '1';
                }
                videoElement.setAttribute('poster', posterImage);
            });

            // Ensure the video is muted for autoplay to work on mobile
            videoElement.muted = true;
        } else {
            console.error(
                'Video element, action button, SVG element, or text span not found in',
                el,
            );
        }
    });
});
