import Glide from '@glidejs/glide';

const contentSliders = document.querySelectorAll('[data-ref="content-slider"]');
// not infinite scrolling
const fixed = document.querySelectorAll('[data-fixed]');
const contentCarousel = document.querySelectorAll(
    '[data-ref="content-carousel"]',
);

let sliderOptions = {
    type: fixed != null ? 'slider' : 'carousel',
    perView: 3,
    gap: 60,
    startAt: 0,
    breakpoints: {
        1450: {
            perView: 1.8,
            gap: 30,
            peek: {
                before: 30,
                after: 30,
            },
        },
        730: {
            perView: 1.6,
            gap: 10,
            peek: {
                before: 10,
                after: 10,
            },
        },
    },
};

let carouselOptions = {
    type: 'slider',
    perView: 4,
    gap: 40,
    startAt: 0,
    slideWidth: 290,
    breakpoints: {
        1450: {
            perView: 3,
            slideWidth: 200,
        },
        730: {
            perView: 1.2,
            gap: 10,
            slideWidth: 190,
        },
    },
};

if (contentCarousel.length > 0) {
    contentCarousel.forEach((carousel) => {
        if (carousel.dataset.center) {
            carouselOptions.focusAt = 'center';
            carouselOptions.startAt = 1;
        }

        const glide = new Glide(carousel, carouselOptions);

        if (carousel.dataset.center) {
            carouselOptions.focusAt = 'center';
        }

        glide.mount({
            visibleClass: (glide, components, events) => {
                const component = {
                    mount() {
                        this.setVisibleClasses();
                    },

                    setVisibleClasses() {
                        if (glide.settings.perView > 0) {
                            const glideEl = components.Html.root;
                            const activeIndex = glide.index;
                            const slides = components.Html.slides;
                            const perView = glide.settings.perView;

                            // Remove visible and hidden classes from all slides
                            slides.forEach((slide) => {
                                slide.classList.remove('u-opacity-1');
                                slide.classList.remove('u-opacity-0');
                                slide.classList.remove('u-opacity-03');
                            });

                            // Add visible class to slides within the perView range
                            for (let i = 0; i < perView; i++) {
                                const index = (activeIndex + i) % slides.length;
                                slides[index].classList.add('u-opacity-1');
                            }

                            // Add hidden class to the item that leaves the visible range
                            const leavingIndex =
                                (activeIndex - 1 + slides.length) %
                                slides.length;
                            if (slides[leavingIndex]) {
                                slides[leavingIndex].classList.add(
                                    'u-opacity-0',
                                );
                            }

                            // Add u-opacity-03 class to the slide waiting to enter the view
                            const enteringIndex =
                                (activeIndex + perView) % slides.length;
                            if (slides[enteringIndex]) {
                                slides[enteringIndex].classList.add(
                                    'u-opacity-03',
                                );
                            }
                        } else {
                            // remove visible and hidden classes from all slides in perview
                            components.Html.root
                                .querySelectorAll('.content-carousel__item')
                                .forEach((slide) => {
                                    slide.classList.remove('u-opacity-1');
                                    slide.classList.add('u-opacity-0');
                                    slide.classList.remove('u-opacity-03');
                                });
                            components.Html.slides[glide.index].classList.add(
                                'u-opacity-1',
                            );
                            components.Html.slides[
                                glide.index
                            ].classList.remove('u-opacity-0');
                        }
                    },
                };
                events.on('run', () => {
                    component.setVisibleClasses();
                });

                return component;
            },
        });
    });
}

if (contentSliders.length > 0) {
    contentSliders.forEach((slider) => {
        if (slider.dataset.center) {
            sliderOptions.focusAt = 'center';
            sliderOptions.startAt = 1;
        }

        const glide = new Glide(slider, sliderOptions);

        glide.mount({
            customActiveClass: (glide, components, events) => {
                const component = {
                    mount() {
                        this.changeActiveSlide();
                    },
                    changeActiveSlide() {
                        const slide = components.Html.slides[glide.index];

                        // Add active animation to next index
                        slide.classList.add('is-active');

                        // Remove from all existing indices
                        [...slide.parentNode.children]
                            .filter((child) => child !== slide)
                            .forEach((sibling) => {
                                sibling.classList.remove('is-active');
                            });

                        const lastIndex = components.Html.slides.length - 1;

                        // Fix for infinite scroll when next card
                        if (glide.index == 0) {
                            const nextSibling =
                                components.Html.slides[lastIndex]
                                    .nextElementSibling;
                            if (nextSibling) {
                                nextSibling.classList.add('is-active');
                            }
                        }

                        // Fix for infinite scroll when prev card
                        if (glide.index == lastIndex) {
                            const prevSibling =
                                components.Html.slides[0]
                                    .previousElementSibling;
                            if (prevSibling) {
                                prevSibling.classList.add('is-active');
                            }
                        }
                    },
                };
                events.on('run', () => {
                    component.changeActiveSlide();
                });
                return component;
            },
        });
    });
}
