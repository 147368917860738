import Glide from '@glidejs/glide';

if (document.querySelectorAll('[data-ref="hero-slider"]').length) {
    const mainSlider = document.querySelectorAll('[data-ref="hero-slider"]')[0];
    const thumbsSlider = document.querySelectorAll(
        '[data-ref="slider-thumbnails"]',
    )[0];
    const slideNumber = thumbsSlider.dataset.index;

    const heroSlider = new Glide(mainSlider, {
        perView: 1,
        autoplay: 3000,
        gap: 0,
    }).mount();

    const sliderThumbnails = new Glide(thumbsSlider, {
        perView: slideNumber,
        bound: true,
        type: 'slider',
        focusAt: 0,
        gap: 20,
    }).mount();

    Array.from(document.querySelectorAll('.hero-slider__nav')).forEach((el) => {
        el.addEventListener('click', (e) => {
            heroSlider.go('=' + (e.target.dataset.index - 1));
            sliderThumbnails.go('=' + (e.target.dataset.index - 1));
        });
    });

    heroSlider.on('swipe.end', () => {
        sliderThumbnails.go('=' + heroSlider.index);
    });

    heroSlider.on('move.after', () => {
        sliderThumbnails.go('=' + heroSlider.index);

        const slideList = thumbsSlider.querySelector('ul').children;

        const activeSlide = slideList.item(heroSlider.index);

        const siblingSlides = [...activeSlide.parentNode.children].filter(
            (child) => child !== activeSlide,
        );

        siblingSlides.forEach((el) => {
            el.classList.remove('active-thumbnail');
        });
        activeSlide.classList.add('active-thumbnail');
    });
}
