const languageModal = document.querySelector('[data-language-modal]');
const languageModalTrigger = document.querySelector(
    '[data-language-modal-trigger]',
);

if (languageModalTrigger && languageModal) {
    languageModalTrigger.addEventListener('click', (event) => {
        event.preventDefault();
        languageModal.classList.toggle('active');
    });
}

const closeLanguageModal = document.querySelectorAll(
    '[data-language-modal-close]',
);

if (closeLanguageModal.length > 0) {
    closeLanguageModal.forEach((close) => {
        close.addEventListener('click', (event) => {
            event.preventDefault();
            languageModal.classList.remove('active');
        });
    });
}
