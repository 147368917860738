const filters = document.querySelectorAll('[data-behaviour="all-filters"]');

if (filters.length > 0) {
    filters.forEach((filter) => {
        let select = filter.querySelectorAll('select');
        const url = window.location.pathname;

        select.forEach((select) => {
            select.addEventListener('change', function (event) {
                if (select.getAttribute('name') === 'category') {
                    let newURL = url.replace(
                        /[^\/]+\/?$/,
                        this.options[this.selectedIndex].getAttribute('data-href') + '/',
                    );
                    filter.setAttribute('action', newURL);
                }
                filter.submit();
            });
        });
    });
}
