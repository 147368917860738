const searchButton = document.querySelector('[data-behaviour="search-button"]');
const searchForm = document.querySelector('[data-behaviour="search-form"]');

if (searchButton) {
    searchButton.addEventListener('click', (e) => {
        e.preventDefault();
        searchButton.classList.toggle('active');
        if (searchForm) {
            searchForm.classList.toggle('open');
            searchForm.classList.toggle('closed');

            const handleTransitionEnd = (e) => {
                if (searchForm.classList.contains('open')) {
                    searchForm.querySelector('input').focus();
                }

                searchForm.removeEventListener(
                    'transitionend',
                    handleTransitionEnd,
                );
            };

            searchForm.addEventListener('transitionend', handleTransitionEnd);
        }
    });
}
