import easydropdown from 'easydropdown';

const dropSelector = document.querySelectorAll(
    '[data-behaviour="dropdown-select"]',
);

if (dropSelector.length > 0) {
    dropSelector.forEach((item) => {
        easydropdown(item, {
            behavior: {
                liveUpdates: true,
                maxVisibleItems: 5,
                showPlaceholderWhenOpen: true,
                useNativeUiOnMobile: true,
            },
        });
    });
}
