const CLASS = {
    active: 'active',
    noScroll: 'u-no-scroll',
};

const ATTR = {
    modal: 'data-subscribe-modal',
    closeTrigger: 'data-subscribe-modal-close',
    behaviour: 'data-behaviour',
};

const BEHAVIOUR = {
    modalTrigger: 'subscribe-modal-button',
};

let currentButton = null;

const body = document.body;
const modal = document.querySelector(`[${ATTR.modal}]`);
const modalTrigger = document.querySelectorAll(
    `[${ATTR.behaviour}="${BEHAVIOUR.modalTrigger}"]`,
);
const closeTrigger = document.querySelectorAll(`[${ATTR.closeTrigger}]`);

const closeModal = (currentButton) => {
    body.classList.remove(CLASS.noScroll);

    body.removeEventListener('keydown', handleBodyKeyDown);

    modal.classList.remove(CLASS.active);

    modal.removeEventListener('click', handleModalClick);

    if (currentButton) {
        currentButton.focus();
    }
};

const openModal = () => {
    body.classList.add(CLASS.noScroll);

    body.addEventListener('keydown', handleBodyKeyDown);

    modal.classList.add(CLASS.active);

    modal.querySelector('input').focus();

    modal.addEventListener('click', handleModalClick);
};

const handleModalClick = function (e) {
    if (e.target === this) {
        closeModal(currentButton);
    }
};

const handleBodyKeyDown = (e) => {
    if (e.key === 'Escape') {
        closeModal(currentButton);
    }
};

const handleCloseTriggerClick = (e) => {
    closeModal(currentButton);
};

const handleTriggerClick = (e) => {
    currentButton = e.target;

    openModal();
    e.preventDefault();
};

modalTrigger.forEach((trigger) => {
    trigger.addEventListener('click', handleTriggerClick);
});

closeTrigger.forEach((closeTrigger) => {
    closeTrigger.addEventListener('click', handleCloseTriggerClick);
});
