// Import static files to ensure webpack loads them correctly
import '../images';
import '../svgs';

// Import standard JS modules and React Components
import './modules';
import './components';

// FontLoader
import { FontLoader } from './tools/index';
const fonts = new FontLoader([
    { name: 'Be Vietnam Pro', weight: 700 },
    { name: 'Hanken Grotesk', weight: 400 },
]);

fonts.load();
